import { render, staticRenderFns } from "./ProductImages.vue?vue&type=template&id=18b19805&scoped=true&"
import script from "./ProductImages.vue?vue&type=script&lang=js&"
export * from "./ProductImages.vue?vue&type=script&lang=js&"
import style0 from "./ProductImages.vue?vue&type=style&index=0&id=18b19805&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18b19805",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VCarousel,VCarouselItem,VImg,VOverlay,VRow,VTab,VTabs})
